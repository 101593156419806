// =============================================================================
//
//  Settings
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

// -----------------------------------------------------------------------------
//  Base:
// -----------------------------------------------------------------------------
$base-radius: 2.2rem;
$base-margin: 1rem;
$base-padding: 1rem;
$border: 0.1rem;
$normal-weight: 400;
$bold: 500;
$bolder: 700;

// -----------------------------------------------------------------------------
//  Screen sizes:
// -----------------------------------------------------------------------------
$desktop-width-huge: 1400px;
$desktop-width-large: 1300px;
$desktop-width-medium-large: 1200px;
$desktop-width-x-large: 1280px;
$desktop-width-medium: 1100px;
$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-only: 1023px;

// -----------------------------------------------------------------------------
//  Colors: Use http://chir.ag/projects/name-that-color/ to name colors
// -----------------------------------------------------------------------------

$black: #000;
$black-two: #1a1a1a;
$mine-shaft: #232323;
$black-three: #2f2f2f;
$greyish-brown: #3f3f3f;
$greyish-brown-two: #3e3e3e;
$brown-grey: #8a8a8a;
$brown-grey-four: #d8d8d8;
$brown-grey-three: #7d7d7d;
$brown-grey-two: #a1a1a1;
$very-light-pink: #dadada;
$very-light-pink-two: #f2f2f2;
$white: #fff;
$light-dark: #252525;
$light-gold: #ffe959;
$parchment: #fef8af;
$cinderella: #fce5e5;
$true-green: #209c00;
$ocre: #cd9300;
$red: #e10000;
$dark-red: #cd0000;
$notification-red: #ad3626;
$light-gray: #e5e5e5;
$transparent: transparent;
$pale-liliac: #e4e4e7;
$dark-cream: #f9ee9a;

// -----------------------------------------------------------------------------
//  New Pallete:
// -----------------------------------------------------------------------------
$transparent: transparent;
$white: #fff;
$red: #e1171c;
$wine-red: #3f0000;
$dark-red: #8a0000;
$black: #020a0a;
$off-black: #1e1e1e;
$pf-grey: #b3b2b1;
$pf-light-grey: #d3d3d3;
$blue: #02275b;
$light-blue: #99b4c7;
$grey: #b3b2b1;
$light-grey: #d3d3d3;
$header-bg: $black;
$dropdown-search-bg: $white;
$dropdown-search-color: $black;
$grey-background: #F0EEEB;

$rookie-color: #F2F2F2;
$pro-color: #FFF8B0;
$master-color: #FEEE5A;
$legend-color: #FEE502;
$light-gray-new: #E9E9E9;
$gray-new: #D9D9D9;
$dark-gray-new: #424242;
$dark-border: #808080;

// -----------------------------------------------------------------------------
//  New Red:
// -----------------------------------------------------------------------------
$red: #8A0000;
$menu-bg: $red;

// -----------------------------------------------------------------------------
//  Typography:
// -----------------------------------------------------------------------------

// Font Families
$roboto-regular: 'Roboto', sans-serif;
$roboto: 'Roboto Condensed', sans-serif;
$teko: 'Teko', sans-serif;
$roboto: 'Roboto Condensed', sans-serif;

// Settings:
$font-use-rem: true;

// Font Sizes:
$fontsize-micro: 10;
$lineheight-micro: 1.2;

$fontsize-tiny: 11;
$lineheight-tiny: 1.2;

$fontsize-mini: 12;
$lineheight-mini: 1.2;

$fontsize-mini-xsmall: 13;
$lineheight-mini-xsmall: 1.2;

$fontsize-xsmall: 14;
$lineheight-xsmall: 1.2;

$fontsize-xsmall-small: 15;
$lineheight-xsmall-small: 1.2;

$fontsize-small: 16;
$lineheight-small: 1.2;

$fontsize-small-compact: 17;
$lineheight-small-compact: 1.2;

$fontsize-compact: 18;
$lineheight-compact: 1.2;

$fontsize-normal: 20;
$lineheight-normal: 1.2;

$fontsize-medium: 21;
$lineheight-medium: 1.2;

$fontsize-medium-big: 22;
$lineheight-medium-big: 1.2;

$fontsize-almost-big: 24;
$lineheight-almost-big: 1.2;

$fontsize-big: 25;
$lineheight-big: 1.2;

$fontsize-large: 26;
$lineheight-large: 1.2;

$fontsize-large-medium: 28;
$lineheight-large-medium: 1.2;

$fontsize-large-xlarge: 29;
$lineheight-large-xlarge: 1.2;

$fontsize-xlarge: 30;
$lineheight-xlarge: 1.2;

$fontsize-huge: 32;
$lineheight-huge: 1.2;

$fontsize-huge-third: 34;
$lineheight-huge-third: 1.2;

$fontsize-enormous: 36;
$lineheight-enormous: 1.2;

$fontsize-enormous-gigantic: 38;
$lineheight-enormous: 1.2;

$fontsize-gigantic: 46;
$lineheight-gigantic: 1.2;

$fontsize-extra-gigantic: 52;
$lineheight-extra-gigantic: 1.2;

$fontsize-legendary: 60;
$lineheight-legendary: 1.2;
