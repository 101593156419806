// =============================================================================
//
//  PLP variables
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

$state-container--filter__padding-bottom: $base-padding * 6;
$state--filter__margin-bottom: $base-margin * 6;
$state--filter__background-color: $white;

$filter-state-top__padding: 0 ($base-margin * 1.8);
$filter-state-top__margin: ($base-margin * 1.2) 0 ($base-margin * 1.4) 0;

$filter-state-top-title__font-weight: $bold;

$filter-state-top-clear__color: $white;

$filter-state-buttons-cancel__max-width: 30%;
$filter-state-buttons-cancel__padding: ($base-padding * 2.2) $base-padding;
$filter-state-buttons-cancel__background-color: $dark-red;
$filter-state-buttons-cancel__color: $white;

$filter-state-buttons-apply__padding: ($base-padding * 2.2) $base-padding;
$filter-state-buttons-apply__background-color: $blue;
$filter-state-buttons-apply__color: $white;

$grid-footer__padding-top: $base-padding * 1.3;
$grid-footer__border-top: $border solid $pale-liliac;
$grid-footer__margin-top: $base-margin * 4;

$grid-footer-container__max-width: 29.4rem;
$grid-footer-container__margin: 0 auto ($base-margin * 5) auto;

$grid-footer-page-count__color: $brown-grey;

$grid-footer-progress-bar__height: 0.5rem;
$grid-footer-progress-bar__margin-top: $base-margin * 2;
$grid-footer-progress-bar__background-color: $pale-liliac;

$grid-footer-progress__background-color: $black-three;

$search-results__width: 100%;
$search-results__max-width: 125.6rem;
$search-results__margin: 0 auto;

$products-grid__width: 100%;

$view-more-products__width: 100%;
$view-more-products__margin-top: $base-margin * 1.7;
$view-more-products__text-align: center;

$view-more-products-link__padding: ($base-padding * 0.9) ($base-padding * 3.6);
$view-more-products-link__font-weight: $bolder;

$product-top-categories-filter-value__margin: ($base-margin * 0.5) $base-margin ($base-margin * 0.5) 0;

$product-top-categories-btn__padding: $base-padding * 0.9 $base-padding * 1;
$product-top-categories-btn__background: $pf-grey;
$product-top-categories-btn__border-radius: 3px;
$product-top-categories-btn__color: $white;

$icon-close__height: 1.4rem;
$icon-close__margin-right: $base-margin * 0.9;
$icon-close__font-size: 1.4rem;

$product-tiles__details__padding-bottom: $base-padding * 3;
$product-tiles__details__margin-top: $base-margin * 2;

$product-tiles__details-title__margin-bottom: $base-margin * 0.5;

$product-tiles__details-description-wrapper__margin-top: $base-margin * 2;

$product-tiles__details-description-wrapper-center__margin: $base-margin * 2 auto 0 auto;

$product-tiles__details-description__margin: $base-margin * 0;
$product-tiles__details-description__color: $greyish-brown;
$product-tiles__details-description__margin-top: $base-margin * 0.5;

$description__line-height: 1.4;
$extended-description__margin: ($base-margin * 3) 0 ($base-margin) 0;

$component-image__margin-top: $base-margin * 2;

$search__margin: ($base-margin * 2) 0 ($base-margin * 2) 0;
$search-results-count__color: $brown-grey;
$search-results-count-bold__color: $black-two;
$search-results-count-bold__font-weight: $bolder;
$search-keywords__margin: ($base-margin * 0.5) 0 ($base-margin) 0;
$search-keywords__font-weight: $bolder;
$search-keywords__color: $black-two;
$search-did-you-mean__color: $brown-grey;
$search-link__margin-top: $base-margin * 0.5;

$sorting-wrapper__border-top: $border solid $pale-liliac;

$sorting-products__padding-top: $base-padding;
$sorting-products__border-top: $border solid $pale-liliac;

$sort-dropdown__container__position: relative;
$sort-dropdown__container__z-index: 10;
$sort-dropdown__container__width: 100%;
$sort-dropdown__container__max-width: 20rem;
$sort-dropdown__container__height: 4rem;

$sort-dropdown-header-title__padding: $base-padding * 0.2 $base-padding * 1.3 $base-padding * 0.2  0;
$sort-dropdown-header-title__font-size: 1.4rem;
$sort-dropdown-header-title-after__top: 0.4rem;
$sort-dropdown-header-title-after__line-height: 1.38;

$sort-dropdown-content__position: relative;
$sort-dropdown-content__z-index: 1;
$sort-dropdown-content__display: block;
$sort-dropdown-content__background-color: $white;

$sort-dropdown-item__display: block;
$sort-dropdown-item__padding: $base-padding * 1.5;
$sort-dropdown-item__color: $black;
$sort-dropdown-item__text-decoration: none;

$sort-dropdown-item__background--hover: $dark-red;
$sort-dropdown-item__color--hover: $white;

$sorting-results__padding-right: $base-padding;

$sorting-text__color: $brown-grey;

$sorting__border-left: $border solid $pale-liliac;
$sorting-border-left__padding: ($base-padding * 1.2) ($base-padding  * 1);

$top-seller-badge__position: absolute;
$top-seller-badge__top: 1rem;
$top-seller-badge__left: 0;
$top-seller-badge__width: 7.5rem;
$top-seller-badge__height: 2.2rem;
$top-seller-badge__background: $black;
$top-seller-badge__color: $white;

$top-categories-list__padding: 0 ($base-padding * 0.6);

$top-categories__margin-bottom: $base-margin * 3;
$top-categories-links__height: 4rem;
$top-categories-links__padding: $base-padding * 1.5;
$top-categories-links__border: $border solid $red;
$top-categories-links__background: $red;
$top-categories-links-hover__background: $wine-red;
$top-categories-links__border-radius: $border * 3;
$top-categories-links__color: $white;
$top-categories-links__font-weight: $bolder;

$top-categories-title__font-weight: $bold;
$top-categories-title__color: $brown-grey;
$top-categories-title__margin-bottom: $base-margin;
$top-categories-title__text-transform: uppercase;

$top-categories-ul__padding: 0 ($base-padding * 1.2);

$filter-results__width: 100%;
$filter-results__height: 5rem;
$filter-results__margin-bottom: $base-margin;
$filter-results__font-weight: $bold;
$filter-results-icon-filter__margin-right: $base-margin;
$filter-results-icon-filter__color: $white;
$filter-results-icon-filter__position: relative;
$filter-results-icon-filter__top: 0.1rem;

$product-grid__margin-top: $base-margin * 2;

$refinement__padding: 0 ($base-padding * 1.8);

$card-body__max-height: 35vh;
$card-body__margin-bottom: $base-margin * 1.5;
$card-body-after__bottom: 1rem;
$card-body-after__height: 3rem;
$card-body-list-cat-number__margin-left: $base-margin;
$card-body-list-cat-number__color: $brown-grey;

$category-ref-button__padding: $base-padding * 1 0;
$category-ref-button__color: $black;
$category-ref-button-top-category__font-weight: $bold;

$card-category-link__padding: $base-padding 0;
$card-category-link-parent__font-weight: $bold;
$card-category-link-active__font-weight: $bolder;
$card-category__padding: 0 ($base-padding * 1.8);

$load-more__height: 4rem;

$card-view-product__border: $border solid $pale-liliac;
$card-view-product__padding-top: $base-padding * 2;

//-----------------------------------------------------------------------------
// Screen Large
// -----------------------------------------------------------------------------

$refinement-active__background-color--large: $white;
$sort-dropdown-after__top--large: 0.7rem;
$sort-dropdown-after__font-size--large: 1.9rem;

$search-keywords__margin--large: ($base-margin * 1) 0 ($base-margin * 2) 0;

$product-tiles__details__max-width--large: 50%;
$product-tiles__details__margin-top--large: $base-margin * 4;
$product-tiles__details-description_margin-top--large: $base-margin * 0.6;

$product-tiles__details-title__margin-bottom: $base-margin;

$sort-dropdown__container__min-width--large: 20rem;

$sorting-results__padding--large: 0 $base-padding;

$top-categories__padding--large: 0 0 ($base-padding * 4) 0;
$top-categories__border-bottom-large: $border solid $very-light-pink;
$top-categories__margin-bottom--large: $base-margin * 1.5;

$top-categories-list__padding--large: 0 ($base-margin * 1.25);
$top-categories-links__height--large: 6rem;

$top-categories-title__font-weight--large: $bold;
$top-categories-title__color--large: $brown-grey;
$top-categories-title__margin-bottom--large: $base-margin * 1.2;
$top-categories-title__text-transform--large: uppercase;

$products-grid-results__margin-top--large: $base-margin * 1.4;

$products-grid-categories__margin-right--large: $base-margin * 2;

$products-grid-wrapper__margin--large: 0 ($base-margin * -1);

$product-portrait-view__width: 50%;
$product__width--large: 25%;
$product-wishlist__width--large: 33.333333%;
$product__padding--large: 0 $base-margin;

$grid-footer__padding-top--large: $base-padding * 0.5;

$grid-footer__container__margin--large: $base-margin * 1 auto $base-margin * 8 auto;

$view-more-products-link__padding--large: ($base-padding * 2) ($base-padding * 3.6);
$view-more-products-link__max-height--large: 6rem;
$view-more-products-link__line-height--large: 1;

$mobile-filters-button__width: 48.5%;
$filter-categories__color: $white;
$filter-categories__font-weight: $bolder;

$state-container--filter__margin-bottom-large: 3rem;

$card-body__max-height--large: 28rem;

$all-campaign-height: 5rem;
$all-campaign-width: 100%;

$p__line-height: 1.4;
