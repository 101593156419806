// =============================================================================
//
//  General Tablet
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@media screen and (min-width: $tablet-width) {
    .wrapper {
        padding: $wrapper__padding--medium;
    }
}
