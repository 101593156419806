// =============================================================================
//
//  Typography Mixins
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

$font-use-rem: true !default;

// -----------------------------------------------------------------------------
//
//  Font-size Base
//  --------------
//  @params $size (string)  "micro|mini|small|normal|medium|large|huge"
//
// -----------------------------------------------------------------------------

@function font-size-base($size: 'small') {
    @if $size == micro {
        @return $fontsize-micro;
    } @else if $size == tiny {
        @return $fontsize-tiny;
    } @else if $size == mini {
        @return $fontsize-mini;
    } @else if $size == mini-xsmall {
        @return $fontsize-mini-xsmall;
    } @else if $size == xsmall {
        @return $fontsize-xsmall;
    } @else if $size == xsmall-small {
        @return $fontsize-xsmall-small;
    } @else if $size == small {
        @return $fontsize-small;
    } @else if $size == small-compact {
        @return $fontsize-small-compact;
    } @else if $size == compact {
        @return $fontsize-compact;
    } @else if $size == normal {
        @return $fontsize-normal;
    } @else if $size == medium {
        @return $fontsize-medium;
    } @else if $size == medium-big {
        @return $fontsize-medium-big;
    } @else if $size == almost-big {
        @return $fontsize-almost-big;
    } @else if $size == big {
        @return $fontsize-big;
    } @else if $size == large {
        @return $fontsize-large;
    } @else if $size == large-medium {
        @return $fontsize-large-medium;
    } @else if $size == large-xlarge {
        @return $fontsize-large-xlarge;
    } @else if $size == xlarge {
        @return $fontsize-xlarge;
    } @else if $size == huge {
        @return $fontsize-huge;
    } @else if $size == huge-third {
        @return $fontsize-huge-third;
    } @else if $size == enormous {
        @return $fontsize-enormous;
    } @else if $size == enormous-gigantic {
        @return $fontsize-enormous-gigantic;
    } @else if $size == gigantic {
        @return $fontsize-gigantic;
    } @else if $size == extra-gigantic {
        @return $fontsize-extra-gigantic;
    } @else if $size == legendary {
        @return $fontsize-legendary;
    } @else {
        @return $fontsize-small;
    }
}

// -----------------------------------------------------------------------------
//
//  Line-Height Base
//  ----------------
//  @params $size (string) "micro|mini|small|normal|medium|large|huge"
//
// -----------------------------------------------------------------------------

@function line-height-base($size: 'small') {

    @if $size == micro {
        @return $lineheight-micro;
    } @else if $size == tiny {
        @return $lineheight-tiny;
    } @else if $size == mini {
        @return $lineheight-mini;
    } @else if $size == mini-xsmall {
        @return $lineheight-mini-xsmall;
    } @else if $size == xsmall {
        @return $lineheight-xsmall;
    } @else if $size == xsmall-small {
        @return $lineheight-xsmall-small;
    } @else if $size == small {
        @return $lineheight-small;
    } @else if $size == small-compact {
        @return $lineheight-small-compact;
    } @else if $size == compact {
        @return $lineheight-compact;
    } @else if $size == normal {
        @return $lineheight-normal;
    } @else if $size == medium {
        @return $lineheight-medium;
    } @else if $size == medium-big {
        @return $lineheight-medium-big;
    } @else if $size == almost-big {
        @return $lineheight-almost-big;
    } @else if $size == big {
        @return $lineheight-big;
    } @else if $size == large {
        @return $lineheight-large;
    } @else if $size == large-medium {
        @return $lineheight-large-medium;
    } @else if $size == xlarge {
        @return $lineheight-xlarge;
    } @else if $size == huge {
        @return $lineheight-huge;
    } @else if $size == enormous {
        @return $lineheight-enormous;
    } @else if $size == gigantic {
        @return $lineheight-gigantic;
    } @else if $size == legendary {
        @return $lineheight-legendary;
    } @else {
        @return $lineheight-small;
    }
}

// -----------------------------------------------------------------------------
//
//  Convert Font Size
//  -----------------
//  @params $from (string)  "micro|mini|small|normal|medium|large|huge"
//  @params $to (string)    "micro|mini|small|normal|medium|large|huge"
//
// -----------------------------------------------------------------------------

// stylelint-disable scss/at-function-pattern
@function convertFontSize($from: 'small', $to: 'small') {
    $from-base-size: font-size-base($from);
    $to-base-size: font-size-base($to);

    @return $to-base-size/$from-base-size;
}

// -----------------------------------------------------------------------------
//
//  Font Size
//  ---------
//  @param $size (string)   "mirco|mini|small|normal|medium|large|huge"
//  @param $precise (bool)  "Force use of px?"
//
// -----------------------------------------------------------------------------

@mixin get-font($size: 'small', $precise: false) {
    $_font-size: font-size-base($size);
    $_line-height: line-height-base($size);

    @if $precise {
        font-size: #{$_font-size}px;
        line-height: $_line-height;
    } @else if $font-use-rem {
        font-size: #{$_font-size/10}rem;
        line-height: $_line-height;
    } @else {
        font-size: #{$_font-size}px;
        line-height: $_line-height;
    }
}
