// =============================================================================
//
//  PLP Large Display
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

@import '../../mixins/media-queries';

@include media-screen-lg {
    body.state--filter {
        overflow: initial;
        
        .filter-state-top {
            display: none;
        }

        .refinements .card.category {
            display: block;
        }
    }
    
    .state-container--filter {
        position: relative;
        margin-bottom: $state-container--filter__margin-bottom-large;
        opacity: 1;
        pointer-events: auto;

        .state--filter & {
            z-index: 1;
            overflow: visible;
            height: auto;
        }

        .filter-state {
            &-wrapper {
                padding: 0;
            }

            &-top {
                display: none;
            }

            &-buttons {
                display: none;
            }
        }
    }

    .state-container--filter,
    .state-container--categories {
        .refinements {
            overflow: initial;
            height: auto;
        }
    }

    .product-tiles__details {
        &-description-wrapper {
            max-width: $product-tiles__details__max-width--large;
            margin-top: 0;
        }

        &-title {
            margin-bottom: $product-tiles__details-title__margin-bottom;
        }

        &-description {
            @include get-font('normal');

            .show-less-description,
            .show-full-description {
                margin-top: $product-tiles__details-description_margin-top--large;
            }

            .short-description,
            .full-description {
                p {
                    @include get-font('normal');
                    line-height: $p__line-height;
                }
            }
        }
    }

    .sorting-wrapper {
        @include flex-wrap(nowrap);

        .sorting-products {
            @include flex-basis(auto);
            padding: 0;
            border: none;

            .sort-dropdown__container {
                min-width: $sort-dropdown__container__min-width--large;
            }
        }
    }

    .sort-dropdown {
        &-header {
            .title {
                @include get-font('compact');
                line-height: 1.95;

                &::after {
                    top: $sort-dropdown-after__top--large;
                    font-size: $sort-dropdown-after__font-size--large;
                }
            }
        }
    }

    .sorting-results {
        @include get-font('compact');
        padding: $sorting-results__padding--large;

        > .sorting-text {
            font-style: normal;
        }
    }

    .sorting-border-left {
        @include get-font('compact');
    }

    .top-categories {
        padding: $top-categories__padding--large;
        border-bottom: $top-categories__border-bottom-large;
        margin-bottom: $top-categories__margin-bottom--large;


        &-title {
            @include get-font('xsmall');
            margin-bottom: $top-categories-title__margin-bottom--large;
            color: $top-categories-title__color--large;
            font-weight: $top-categories-title__font-weight--large;
            text-transform: $top-categories-title__text-transform--large;
        }

        &-ul {
            @include justify-content(space-between);
            right: 0;
            left: 0;
            width: 100%;
            padding: 0;
            margin: 0;
        }

        &-list {
            @include flex-grow(1);
            padding: $top-categories-list__padding--large;
        }

        &-links {
            @include get-font('compact');
            height: $top-categories-links__height--large;
        }
    }

    .refinement {
        padding: 0;

        &.active {
            background-color: $refinement-active__background-color--large;

            .card-body {
                max-height: $card-body__max-height--large;
            }
        }

        &.refinement-visa-endast-ekologiska-produkter {
            .card-header {
                display: none;
            }

            .collapsible-content {
                height: auto;
                padding-top: $base-padding * 1.5;
                padding-bottom: 0;
            }
        }
    }

    .card.category {
        padding: 0;
    }

    .products-grid-results {
        @include flexbox();
        @include flex-flow(row nowrap);
        margin-top: $products-grid-results__margin-top--large;
    }

    .products-grid-categories {
        display: block !important;
        width: 25%;
        margin-right: $products-grid-categories__margin-right--large;
    }

    .product-grid {
        margin-top: 0;
    }

    .products-grid-wrapper {
        @include flexbox();
        @include flex-flow(row wrap);
        width: 100%;
        //margin: $products-grid-wrapper__margin--large;

        .product {
            width: $product__width--large;
            padding: $product__padding--large;

            &.wishlist-product {
                width: $product-wishlist__width--large;
            }
        }
    }

    .grid-footer {
        padding-top: $grid-footer__padding-top--large;
        border-top: none;
        margin-top: 0;

        &__container {
            margin: $grid-footer__container__margin--large;
        }

        &-page-count {
            @include get-font('small');
        }

        &-product-count {
            @include get-font('compact');
        }

        .view-more-products {
            > .link {
                @include get-font('medium');
                width: 100%;
                max-height: $view-more-products-link__max-height--large;
                padding: $view-more-products-link__padding--large;
                font-weight: $bolder;
                line-height: $view-more-products-link__line-height--large;
            }
        }
    }

    .search {
        &-keywords {
            @include get-font('enormous-gigantic');
            margin: $search-keywords__margin--large;
        }
    }
}

.card-category-link,
.card-body-list .category-ref-button {
    @include get-font(small);
}
