// =============================================================================
//
//  General Variables
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

$html__background-color: $white;
$html__color: $black;
$html__font-weight: $normal-weight;

$wrapper__padding: 0 ($base-padding * 1.2);
$wrapper__padding--medium: 0 ($base-padding * 1.2);
$wrapper__padding--large: 0 ($base-padding * 9);

$wrapper-header__padding: 0 ($base-margin * 1.8);
$wrapper-header__padding--large: 0 ($base-margin * 1.5);
$wrapper-header__padding--medium-large: 0 ($base-margin * 2.5);

$header-top__height: 3.5rem;
$header-top__height--large: 4rem;
$header-main__height: 5rem;
$header-main__height--large: 8rem;
$header-navigation__height--large: 5rem;
$header-search__padding-top-bottom: 1.2rem;

$page__padding-top: calc(#{$header-top__height} + #{$header-main__height} * 2);
$page__padding-top--large: calc(#{$header-top__height--large} + #{$header-main__height--large} + #{$header-navigation__height--large});

$account-user-logged-in__color: $white;
$account-user-logged-in__border-bottom: 0.2rem solid $white;
$account-user-logged-in__padding-bottom: $base-padding * 0.3;
$account-user-logged-in__padding-bottom--large: $base-padding * 1.2;
$account-user-logged-in-sticky__padding-bottom--large: $base-padding * 0.2;
