// =============================================================================
//
//  Search
//
//  @version    0.0.1
//  @package    Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

@import 'settings';
@import 'mixins/flex';
@import 'typography/mixins';
@import 'general';

@import './themes/default/search';

body {
    &.state--filter {
        overflow: hidden;
               
        .refinement {
            &.active {
                background: $white;

                @media screen and (max-width: $mobile-only) {
                    background: $black;
                }
            }

            &-category {
                display: none;
            }
        }

        .card.category {
            display: none;
        }

        .filter-state-top {
            @include flexbox();
            height: auto;
            opacity: 1;
        }

        .refinements-no-category {
            display: none !important; /* to overwrite general 'hide' class from template */
        }
    
        &.state--categories {
            .refinement {
                display: none;

                &-category {
                    display: none;
                }
            }

            .refinements-no-category {
                display: block !important; /* to overwrite general 'hide' class from template */
                margin-top: $base-margin;
            }

            .card.category {
                display: block;
            }

            .filter-state-top {
                display: none;
                height: 0;
                opacity: 0;
            }

            .filter-state-buttons {
                .filter-state-buttons-apply {
                    display: none;
                }

                .filter-state-buttons-cancel {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

.state-container--filter,
.state-container--categories {
    padding-bottom: $state-container--filter__padding-bottom;
    opacity: 0;
    pointer-events: none;

    .state--filter & {
        z-index: 999999;
        margin-bottom: $state--filter__margin-bottom;
        background-color: $white;
        opacity: 1;
        pointer-events: auto;

        @media screen and (max-width: $mobile-only) {
            background-color: $black;
        }
    }

    .filter-state {
        &-top {
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            padding: $filter-state-top__padding;
            margin: $filter-state-top__margin;
            color: $filter-state-top-clear__color;

            &-title {
                @include get-font('xsmall');
                font-weight: $filter-state-top-title__font-weight;
            }

            &-clear {
                color: $filter-state-top-clear__color;
                text-decoration: underline;
            }
        }

        &-buttons {
            @include flexbox();
            position: fixed;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;

            &-cancel {
                @include get-font('xsmall');
                @include flex-grow(1);
                max-width: $filter-state-buttons-cancel__max-width;
                padding: $filter-state-buttons-cancel__padding;
                background-color: $filter-state-buttons-cancel__background-color;
                color: $filter-state-buttons-cancel__color;
                text-align: center;
            }

            &-apply {
                @include get-font('xsmall');
                @include flex-grow(1);
                padding: $filter-state-buttons-apply__padding;
                background-color: $filter-state-buttons-apply__background-color;
                color: $filter-state-buttons-apply__color;
                text-align: center;
            }
        }
    }

    @media screen and (max-width: $mobile-only) {
        .collapsible-header > .title,
        .form-group .form-control-checkbox-title,
        .form-group .form-control-label {
            color: $white;
        }

        input[type='checkbox']:checked + .form-control-label::before {
            background-color: $white;
        }

        input[type='checkbox']:checked + .form-control-label .checkbox-icon { // stylelint-disable selector-max-compound-selectors
            color: $black;
        }
    }

    .refinements {
        height: 100%;
        overflow-y: auto;
    }
}

.grid-footer {
    width: 100%;
    padding-top: $grid-footer__padding-top;
    border-top: $grid-footer__border-top;
    margin-top: $grid-footer__margin-top;

    &__container {
        max-width: $grid-footer-container__max-width;
        margin: $grid-footer-container__margin;
    }

    &-page-count {
        @include get-font('xsmall');
        color: $grid-footer-page-count__color;
        text-align: center;
    }

    &-progress-bar {
        width: 100%;
        height: $grid-footer-progress-bar__height;
        margin-top: $grid-footer-progress-bar__margin-top;
        background-color: $grid-footer-progress-bar__background-color;
    }

    &-progress {
        max-width: 100%;
        height: 100%;
        background-color: $grid-footer-progress__background-color;
    }

    &-product-count {
        @include get-font('small');
        margin-top: $base-margin;
        text-align: center;
    }

    .view-more-products {
        width: $view-more-products__width;
        text-align: $view-more-products__text-align;


        > .link {
            @include get-font('small');
            width: 100%;
            min-height: $load-more__height;
            padding: $view-more-products-link__padding;
            font-weight: $view-more-products-link__font-weight;
        }
    }

    // stylelint-disable max-nesting-depth

    &-pagination {
        @include get-font('small');
        margin-bottom: 3rem;
        color: $brown-grey;
        margin-left: auto;
        margin-right: auto;

        &-list {
            padding: 0;
            margin: 0;
            text-align: center;

            &-item {
                display: inline-block;
                padding: 0 $base-padding * 0.5;

                &-link {
                    @include get-font('small');
                    display: block;
                    color: $brown-grey;
                    text-decoration: none;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $black-two;
                    }
                }
            }
        }
    }
}

.product-top-categories {
    .filter-value {
        display: inline-block;
        margin: $product-top-categories-filter-value__margin;
    }

    &-btn {
        @include flexbox();
        @include align-items(center);
        @include get-font('small');
        padding: $product-top-categories-btn__padding;
        border-radius: $product-top-categories-btn__border-radius;
        background: $product-top-categories-btn__background;
        color: $product-top-categories-btn__color;
        font-weight: normal;

        &:hover {
            background: $pf-light-grey;
            color: $white;

            .icon-close {
                color: $red;
            }
        }

        .icon-close {
            height: $icon-close__height;
            margin-right: $icon-close__margin-right;
            color: $white;
            font-size: $icon-close__font-size;
        }
    }
}

.product-tiles__details {
    padding-bottom: $product-tiles__details__padding-bottom;

    &-description-wrapper {
        @include flexbox();
        @include flex-wrap(wrap);
        margin-top: $product-tiles__details-description-wrapper__margin-top;

        &.left {
            @include justify-content(flex-start);
            text-align: left;
        }

        &.center {
            @include justify-content(center);
            margin: $product-tiles__details-description-wrapper-center__margin;
            text-align: center;
        }

        &.right {
            @include justify-content(flex-end);
            margin-left: auto;
            text-align: right;
        }
    }

    &-title {
        width: 100%;
        margin-bottom: $product-tiles__details-title__margin-bottom;
    }

    &-description {
        @include get-font('compact');
        margin: $product-tiles__details-description__margin;
        color: $product-tiles__details-description__color;

        .short-description,
        .full-description {
            color: $product-tiles__details-description__color;
            line-height: $description__line-height;

            p {
                @include get-font('normal');
            }
        }

        .show-less-description,
        .show-full-description {
            margin-top: $product-tiles__details-description__margin-top;
            color: $product-tiles__details-description__color;
            float: right;
            text-decoration-color: $product-tiles__details-description__color;
        }

        .extended-description {
            margin: $extended-description__margin;
        }

        .hide {
            display: none;
        }
    }

    .component-image {
        width: 100%;
        margin-top: $component-image__margin-top;
    }
}

.product-tiles__details .component-image:-moz-broken {
    width: 0;
    height: 0;
    -moz-force-broken-image-icon: 0;
}

.search {
    margin: $search__margin;

    &-result-count {
        @include get-font('xsmall');
        color: $search-results-count__color;

        .bold {
            color: $search-results-count-bold__color;
            font-weight: $search-results-count-bold__font-weight;
        }
    }

    &-keywords {
        @include get-font('large');
        margin: $search-keywords__margin;
        color: $search-keywords__color;
        font-weight: $search-keywords__font-weight;
    }

    &-did-you-mean {
        @include get-font('xsmall');
        color: $search-did-you-mean__color;
    }

    &-link {
        margin-top: $search-link__margin-top;
    }
}

.sorting-wrapper {
    @include flexbox();
    @include flex-wrap(wrap);

    .product-top-categories {
        @include flex(1);
    }

    .sorting-products {
        @include flexbox();
        @include flex(0 0 100%);
        @include align-items(center);
        @include justify-content(flex-end);
        padding-top: $sorting-products__padding-top;
        border-top: $sorting-products__border-top;

        .dropdown__container {
            position: $sort-dropdown__container__position;
            z-index: $sort-dropdown__container__z-index;
            width: $sort-dropdown__container__width;
            max-width: $sort-dropdown__container__max-width;
            height: $sort-dropdown__container__height;
        }
    }
}

.sort-dropdown {
    &.active {
        .sort-dropdown-content {
            padding: 0;

            .sort-dropdown-item {
                padding: $sort-dropdown-item__padding;
            }
        }
    }

    .sort-dropdown-content {
        display: none;
    }

    &-header {
        .title {
            padding: $sort-dropdown-header-title__padding;
            font-size: $sort-dropdown-header-title__font-size;

            &::after {
                top: $sort-dropdown-header-title-after__top;
                line-height: $sort-dropdown-header-title-after__line-height;
            }
        }
    }

    &-content {
        position: $sort-dropdown-content__position;
        z-index: $sort-dropdown-content__z-index;
        display: $sort-dropdown-content__display;
        padding: 0;
        background-color: $sort-dropdown-content__background-color;

        .sort-dropdown-item {
            display: $sort-dropdown-item__display;
            padding: $sort-dropdown-item__padding;
            margin: 0;
            color: $sort-dropdown-item__color;
            text-decoration: $sort-dropdown-item__text-decoration;

            &:hover {
                background: $sort-dropdown-item__background--hover;
                color: $sort-dropdown-item__color--hover;
            }
        }
    }
}

.sorting-results {
    @include get-font('xsmall');
    @include flex-grow(1);
    padding-right: $sorting-results__padding-right;

    > .sorting-text {
        color: $sorting-text__color;
        font-style: normal;
    }
}

.sorting-border-left {
    @include get-font('xsmall');
    padding: $sorting-border-left__padding;
    border-left: $sorting__border-left;
}

.top-seller-badge {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include get-font('mini');
    position: $top-seller-badge__position;
    top: $top-seller-badge__top;
    left: $top-seller-badge__left;
    width: $top-seller-badge__width;
    height: $top-seller-badge__height;
    background: $top-seller-badge__background;
    color: $top-seller-badge__color;
}

.top-categories {
    margin-bottom: $top-categories__margin-bottom;

    &-title {
        @include get-font('mini');
        margin-bottom: $top-categories-title__margin-bottom;
        color: $top-categories-title__color;
        font-weight: $top-categories-title__font-weight;
        text-transform: $top-categories-title__text-transform;
    }

    &-ul {
        @include flexbox();
        position: relative;
        right: 50%;
        left: 50%;
        overflow: auto;
        width: 100vw;
        padding: $top-categories-ul__padding;
        margin: 0 -50vw;
        list-style: none;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-list {
        @include flex-shrink(0);
        padding: $top-categories-list__padding;
        cursor: pointer;

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }
    }

    /* stylelint-disable property-no-vendor-prefix */

    &-links {
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        @include get-font('small');
        height: $top-categories-links__height;
        padding: $top-categories-links__padding;
        border: $top-categories-links__border;
        border-radius: $top-categories-links__border-radius;
        background: $top-categories-links__background;
        color: $top-categories-links__color;
        font-weight: $top-categories-links__font-weight;
        text-decoration: none;
        -webkit-transition: background-color 0.3s ease;
        -moz-transition: background-color 0.3s ease;
        -o-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;

        &:hover {
            border: $border solid $top-categories-links-hover__background;
            background: $top-categories-links-hover__background;
            color: $white;
        }
    }
}

.category-ref-button {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    width: 100%;
    font-weight: normal;
    text-align: left;


    .top-category {
        font-weight: $category-ref-button-top-category__font-weight;
    }
}

.filter-results {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include get-font('compact');
    width: $filter-results__width;
    height: $filter-results__height;
    margin-bottom: $filter-results__margin-bottom;
    cursor: pointer;
    font-weight: $filter-results__font-weight;

    .icon-filter {
        @include get-font('compact');
        position: $filter-results-icon-filter__position;
        top: $filter-results-icon-filter__top;
        margin-right: $filter-results-icon-filter__margin-right;
        color: $filter-results-icon-filter__color;

    }
}

.product-grid {
    width: 100%;
    margin-top: $product-grid__margin-top;
}

//Refinements

.refinements.loading {
    opacity: 0.5;
    pointer-events: none;
}

.refinement {
    padding: $refinement__padding;
    outline: none;

    &.active {
        position: relative;

        .card-body {
            max-height: $card-body__max-height;
            padding: 0;
            margin-bottom: $card-body__margin-bottom;
            overflow-y: auto;

            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                bottom: $card-body-after__bottom;
                left: 0;
                width: 100%;
                height: $card-body-after__height;
                pointer-events: none;
            }
        }
    }

    &-bar {
        display: block !important; /* add this to overrite inline display: none; styling which is added by state js */

        .card-title {
            margin: 0;
        }
    }
}

.card {
    &-title {
        @include get-font('small');
        font-weight: $normal-weight;
    }

    &-body-list-cat-number {
        @include get-font('xsmall');
        margin-left: $card-body-list-cat-number__margin-left;
        color: $card-body-list-cat-number__color;
    }

    &-body-list .category-ref-button {
        @include get-font('xsmall');
        padding: $category-ref-button__padding;
        margin: 0;
        background: none;
        color: $category-ref-button__color;
        outline: none;
    }

    &-category-link {
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        @include get-font('xsmall');
        padding: $card-category-link__padding;
        text-decoration: none;

        @media screen and (max-width: $mobile-only) {
            color: $white;
        }

        &.parent {
            font-weight: $card-category-link-parent__font-weight;
        }
        
        &.active {
            font-weight: $card-category-link-active__font-weight;
        }
    }

    &.category {
        padding: $card-category__padding;
    }
}

.mobile-filters {
    @include flexbox;
    @include flex-flow(wrap);
    @include justify-content(space-between);

    > * {
        width: $mobile-filters-button__width;
    }

    .content-asset {
        @include flexbox();

        .all-campaigns {
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            @include get-font('compact');
            width: $all-campaign-width;
            height: $all-campaign-height;
            padding: 0;
            color: $filter-categories__color;
            font-weight: $filter-categories__font-weight;
            text-decoration: none;
        }
    }

    .filter-categories {
        @include get-font('compact');
        width: $mobile-filters-button__width;
        height: 5rem;
        padding: 0;
        color: $filter-categories__color;
        font-weight: $filter-categories__font-weight;
    }

    .filter-results {
        width: $mobile-filters-button__width;
        padding: 0;
        
        &.filter-button-full-width {
            width: 100%;
        }
    }
}

// Card View Mobile Layout
@media screen and (max-width: $tablet-width) {
    .card-view {
        .products-grid-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column: 1 / -1;

            .product {
                border: none;
                border-bottom: $card-view-product__border;
                padding-top: $card-view-product__padding-top;
            }

            .product:nth-child(2n+1) {
                padding-right: $base-padding;
                border-right: $card-view-product__border;
            }

            .product:nth-child(2n+2) {
                padding-left: $base-padding;
            }

            .grid-footer {
                grid-column: 1 / -1;
            }
        }
    }
}

// Portrait Mobile Layout
@media screen and (max-width: $tablet-width) {
    .portrait-view {
        .products-grid-wrapper {
            @include flexbox();
            @include flex-flow(row wrap);
            margin: 0 -$base-margin;

            .products-grid-wrapper {
                margin: 0;
            }

            .product {
                width: $product-portrait-view__width;
                padding: 0 $base-padding;
            }
        }

        .grid-footer {
            margin-left: $base-margin;
            margin-right: $base-margin;
        }
    } 
}



//-----------------------------------------------------------------------------
// Screen Large
// -----------------------------------------------------------------------------
@import 'screens/large/search';

